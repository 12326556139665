import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => (
  <Layout>
    <h1>Uh oh!</h1>
    <p>
    I think I did something wrong.
    </p>
    <p>You may be looking for something and I might have crossed some wires.</p>
    <p>Send me a message about what you were looking for and I will get sorted out.</p>
    <p>While you wait, let's <a href="/">take you back to somewhere safe</a></p>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`